import React from "react";
import { useState } from "react";
import {Butterfly} from "./components/butterfly";

export const App = () => {
    const [theme, setTheme] = useState('dark');
    const [themeText, setThemeText] = useState('drk');

    const toggleTheme = () => {
        if(theme === 'dark') {
            setTheme('light');
            setThemeText('lgt');
            document.getElementById('body').setAttribute('class', 'bg-light');
        } else {
            setTheme('dark');
            setThemeText('drk');
            document.getElementById('body').setAttribute('class', 'bg-dark');
        }
    };

    return (
        <React.Fragment>
            <h3 className='text-center w-100 pt-5' id="header">
                <span className={'terminal-text rainbow-text'}>{"<portfolio/>"}</span>
            </h3>
            <div className='text-center w-100' id="body">
                <Butterfly toggleTheme={toggleTheme} themeText={themeText} themeClass={'text-color-'+theme}/>
            </div>
            <div className="text-center w-100" id="footer">

            </div>
        </React.Fragment>
    );
}
