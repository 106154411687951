import React from "react";

export const ButterflyLink = ({link, onclick: onClick, children}) => {
    if(link)
        return (
            <a className="link" href={link} onClick={onClick} target="_blank" rel="noreferrer">
                {children}
            </a>
        );
    return (
        <span className="link" onClick={onClick} target="_blank" rel="noreferrer">
            {children}
        </span>
    );
}
