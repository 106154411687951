import React from "react";
import {ButterflyLink} from "./butterfly-link";

export const Butterfly = ({toggleTheme, themeText, themeClass}) => {
    return (
        <pre className={'d-inline-block terminal-text ' + themeClass}>
            <span>                              :,                      :xl</span><br/>
            <span>                                ;<ButterflyLink onclick={toggleTheme}>{themeText}</ButterflyLink>.               'dk:        ;x0: </span><br/>
            <span>                      lxc.         .ldo'         'oOd.        xNOc. </span><br/>
            <span>                        ,d0o.         .cxdcdXKxkWk,         .XK. </span><br/>
            <span>                           lK,        .cXMMMMMMMMKdo'       xMo </span><br/>
            <span>                            :X'      ,NMMWN<ButterflyLink link='#'>GTZ</ButterflyLink>NWMMMWO    .cd0:            ..,:clodxdoc,. </span><br/>
            <span>      .....                  xMNx;   .kWMNXXNNXXMMNK,  .xWWd.      .'clx0WWXXNWXXWMWXXNNK. </span><br/>
            <span>  ;kKXXXNMMWXK0Oxxoc,'.       'd0XKoc;,WMNXXWNXXMMNKcxKXkl.   .:d0KXXNXXNMM<ButterflyLink link='https://github.com/sillydan1/aaltitoad/blob/master/.github/resources/docs/SW9__AALTITOAD.pdf'>PAPER1</ButterflyLink>WMMNXXNx </span><br/>
            <span> 'WWWW<ButterflyLink link='https://www.xbox.com/en-us/games/store/clash/bvh4l9tkg8bl'>CLASH</ButterflyLink>MMWWWWMMMWWWWX0xl;.    ,ONXXMMWWWMWWWMMMMWWO..,lkKWWMMMMWWWXXNMMMMMMWWMMMWN: </span><br/>
        <span>  KMMMMMMMMWWMMMMM<ButterflyLink link="https://github.com/sillydan1/graphedit">GRAPHEDIT</ButterflyLink>MMMMNKkdoxXWMMMMMMMMMMMMMMMWWMMMMMMMMMMMN<ButterflyLink link="https://github.com/sillydan1/aaltitoad/blob/master/.github/resources/docs/SW10__Tick_Tock_Automata.pdf">PAPER2</ButterflyLink>MMMMMMMMMMWd. </span><br/>
            <span>   xMMM<ButterflyLink link='https://github.com/sillydan1/AALTITOAD'>AALTITOAD</ButterflyLink>MMMMMMMMMMMWWWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM<ButterflyLink link="https://github.com/sillydan1/aaltitoad/blob/master/.github/resources/docs/aaltitoad-v1.0.0.pdf">PAPER3</ButterflyLink>MMMMMMMMMMMWMKd. </span><br/>
            <span>    .lKNWMMMMMMMMMMMMMMMMMMNNNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMx:,. </span><br/>
            <span>       .:dk0Od0XNW<ButterflyLink link="https://github.com/yalibs">YALIBS</ButterflyLink>NMMNNNMMWNXNNKkdWWNNNXXWMMWNOlONMMWNXXNWMMNNXNNNWMMKl </span><br/>
            <span>              ,0NNMMNXXNNMMNXXMMNKd:..,cNNXXWNXXMMNX0xl.'cx0XXNWMWXXNWXXKx, </span><br/>
            <span>                'lONNNXXNMMNXKkc. .'cXXMMNXXWNXXMMWNXNX0kl;',;:oOK0Oxl;. </span><br/>
            <span>                    ..;codoc. ,d0NNKllNMMMMMMMMMMMMMMW0 .l0WWWNl </span><br/>
            <span>                             oMWOc. .XMMMMMMMMMMMMMMMMMx    ':XM0. </span><br/>
            <span>                           .0N,    'NMMMMMMMMMMMMMMMMMMMX:    :WMk </span><br/>
            <span>                           kNx    ;WWWMMMMMMMWWMMMMMMWMWWM0.   .oKc. </span><br/>
            <span>                        .:Ox.    ;XXcOXMMNXXNXXNMMWNXNXK,NWK.    .l0Oo;. </span><br/>
            <span>                     ;dOOo.     .XXo oX<ButterflyLink link='https://github.com/sillydan1'>GH</ButterflyLink>NXXWNXXMMNXXNNd  o0k'      .,c' </span><br/>
            <span>                     .'       .oNK.  .XMMWNNWNNN<ButterflyLink link='https://twitter.com/AsgerGitz'>TWT</ButterflyLink>NNXX.    kXKc </span><br/>
            <span>                             .NMO.    lM<ButterflyLink link='https://linkedin.com/in/asgergitz'>LDin</ButterflyLink>MMMMMMMMMM:      :kK; </span><br/>
            <span>                            ,0o.       xMMMMMMMMMMMMMc          cKk:. </span><br/>
            <span>                          cOx.          oMMMMMMM<ButterflyLink link='https://blog.gtz.dk'>BLOG</ButterflyLink>,             ;xo </span><br/>
            <span>                         .;.             '0W<ButterflyLink link='#'>CV</ButterflyLink>WMMWo </span><br/>
            <span>                                           'oO0kc.</span>
        </pre>
    );
}

